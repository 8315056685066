/* z-indexes are large due to the fact that things might change
 * in the future. In order to account for scalability, this gives
 * room to make changes and not break the entire site, meaning 
 * we won't have to go in and refactor a lot of our code.
 */

$page-background          : 500;

$navigation-bar           : 1500;
$navigation-bar-container : $navigation-bar;
$global-search-mobile     : -1;

$scroll-bar               : 1100;

$main                     : 1000;
$header                   : 1000;
$side-bar                 : 1750;

$modal                    : 10000;