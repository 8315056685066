@use '../_variables' as *;
@use 'modules/colour/encode' as colour;
@use "font";
@use '../_helpers/misc.scss' as *;

$base-input-padding: $gap;


@mixin arrow-down-image($stroke : var(--font-colour)) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.4' height='10.1'%3E%3Cpath d='M16.7.7l-8 8-8-8' fill='none' stroke='"+colour.encode($stroke)+"' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E");
}


/*  ----------------------------------------------------
    INPUTS
  ----------------------------------------------------- */

@mixin character-inputs() {
  input[type="text"], input[type="email"], input[type="number"], input[type="search"], select, textarea {
    @content;
  }
}

@mixin all-inputs() {
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  select,
  textarea {
    @content;
  }
}

@mixin character-input() {
  --font-colour       : #{$font-colour};
  --background-colour : #{$white};
  --padding           : calc(#{$base-input-padding} / 1.5) #{$base-input-padding};
  --border-colour     : #{$grey7};
  --border-radius     : 0;

  outline: 0;
  border: 1px solid var(--border-colour);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: $border-radius;  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  -webkit-border-radius: 0;  /* Safari 3-4, iOS 1-3.2, Android 1.6- */    
  -moz-border-radius: 0;  /* Firefox 1-3.6 */     

  color: var(--font-colour);
  background-color: var(--background-colour);
  padding: var(--padding);
  transition: box-shadow $transition;
  box-shadow: 0px 0px 0px 0px transparent;

  &::placeholder { transition: color $transition }

  // &:not(:empty) {
  //   &::placeholder { color: $transparent }
  // }
}


/*  ----------------------------------------------------
    CONTAINERS
  ----------------------------------------------------- */

@mixin container() {
  --label-colour        : var(--font-colour);
  --label-active-colour : var(--theme-accent);

  display: grid;
  grid-gap: 1rem;

  label {
    color: var(--label-colour);
    transition: color $transition;
  }
}

@mixin text-container() {
  @include container();
  grid-auto-flow: row;
}


@mixin radio-checkbox-container() {
  @include container();
  grid-auto-flow: column;

  --input-width: 1.2em;
  --input-border-thickness: 1px;
  --input-active-colour: #{$red};

  label {
    display: grid;
    grid-auto-flow: column;
    grid-gap: $gap;
    align-items: center;
    width: fit-content;

    &::before, &::after {
      grid-row: 1;
      grid-column: 1;
      content: '';
      position: relative;
      display: block;
    }

    &::before {
      @include square(var(--input-width));
      background: $white;
      border: var(--input-border-thickness, 1px) solid $grey;
      transition: border-color $transition;
      cursor: pointer;
    }

    &::after {
      @include square(calc(var(--input-width) - var(--input-border-thickness) * 6));
      background: var(--input-active-colour);
      opacity: 0;
      left: -10px;
      transition: opacity $transition, transform $transition;
      pointer-events: none;
      justify-self: center;
    }

    &:hover {
      --label-colour: var(--label-active-colour);

      &::before {
        border-color: var(--input-active-colour);
      }
    }
  }

  input {
    display: none;

    &:checked {
      + label {
        color: var(--label-active-colour);

        &::after {
          opacity: 1;
          transform: translateX(10px);
        }
      }
    }
  }
}

@mixin radio-container() {
  label {
    &::before, &::after {
      border-radius: 50%;
    }
  }
}

@mixin multi-input() {
  --input-spacing: 0;
  
  display: flex;
  gap: var(--input-spacing);
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: fit-content;

  @include character-inputs() {
    &:not(select) {
      border: 1px solid var(--border-colour);
      border-right: 0;
    }
  }

  > *:not(select, button, a) {
    flex: 1;
  }
  
  select, button, a {
    --font-colour: #{$white};
    --background-colour: #{$red};

    text-transform: uppercase;
    border-left: 1px solid var(--border-colour);
    background-color: var(--background-colour);
    color: var(--font-colour);
    font-weight: 600;
  }

  select { @include arrow-down-image($white) };
}

@mixin base-required() {
  required-notice {
    --notice-colour: #{$red};

    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    color: var(--notice-colour);
    font-size: 1.5em;

    span {
      position: absolute;
      right: -100%;
      pointer-events: none;
      white-space: nowrap;
      font-size: $font-size;
      opacity: 0;
    }
  }
}

@mixin radio-tabs() {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  radio-tab {
    position: relative;
    display: block;
    font-weight: 400;
    text-transform: uppercase;
    padding-bottom: $gap0;
    
    input { 
      display: none;

      &:checked + label {
        font-weight: 600;

        &::after {
          background: var(--theme-accent);
        }
      }

      &:not(:checked) {
        + label:hover::after {
          background: var(--theme-accent);
        }
      }
    }

    label {
      color: var(--font-colour);
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: $transparent;
        transition: background $transition;
      }
      

    }

  }
}


@mixin input-focus() {
  box-shadow: 0px 0px 1px 1px rgba($red, 0.3);
}