@use '../_variables' as *;
@use '../_helpers/misc.scss' as *;

@mixin tag() {
  &[disabled] { @include disabled() }
  &.category {
    @include tag-base();
    --border-colour: #{$red};
    @include tag-with-secondary();
    .tag-secondary:hover { @include secondary-hover() }
  }

  &.checkbox {
    @include tag-checkbox();
  }
}

/* -----------------------------------------------------
  Base styling
----------------------------------------------------- */
@mixin tag-base() {
  --border-radius       : 0px;
  --font-colour         : #{$red};
  --bg-colour           : white;
  --secondary-width     : 3ch;
  --secondary-padding-x : 3px;
  --border-colour       : transparent;

  position: relative;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: fit-content;
  border: 1px solid var(--border-colour);
  border-radius: var(--border-radius);
  color: var(--font-colour);
  background-color: var(--bg-colour);
  transition: $transition;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: content-box;

  .tag-label {
    padding: 3px $gap 3px $gap;
  }
}

/* -----------------------------------------------------
  With secondary section
----------------------------------------------------- */
@mixin tag-with-secondary() {
  --symbol-colour : #{$red};

  .tag-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--secondary-padding-x);
    height: 100%;
    background-color: $grey4;
    overflow: hidden;
    width: 3ch;
    transition: $transition;

    svg {
      width: 50%;
      transition: $transition;
    }
  }
}


/* -----------------------------------------------------
  Checkbox tags
----------------------------------------------------- */
@mixin tag-checkbox() {
  input[type="checkbox"] {
    display: none;

    & ~ label {
      @include tag-base();
      @include tag-with-secondary();

      &:hover {
        --border-colour: #{$red};
        cursor: pointer;
      }
    }

    &:checked ~ label {
      --font-colour: white;
      --bg-colour: #{$red};

      .tag-secondary { @include secondary-hover(rgba(white, 0.3)) }
    }
  }
}


/* -----------------------------------------------------
  States
----------------------------------------------------- */
@mixin secondary-hover($colour : $red) {
  --symbol-colour: white;
  background-color: $colour;
}