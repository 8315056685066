@use '../_variables' as *;

@mixin mark() {
  background-color: $colours-mark;
  color: white;
  border-radius: calc(#{$border-radius} / 2);
  padding:0 5px;
  transition: color $transition, background-color $transition;
}

@mixin mark-reset() {
  background-color: initial;
  color: inherit;
  border-radius: initial;
  padding:initial;
  transition: initial;
}