/* z-indexes are large due to the fact that things might change
 * in the future. In order to account for scalability, this gives
 * room to make changes and not break the entire site, meaning 
 * we won't have to go in and refactor a lot of our code.
 */
/*  ----------------------------------------------------
    INPUTS
  ----------------------------------------------------- */
/*  ----------------------------------------------------
    CONTAINERS
  ----------------------------------------------------- */
/* -------------------
	Base result styles
-------------------- */
/* -------------------
	Variations
-------------------- */
/* -----------------------------------------------------
  Base styling
----------------------------------------------------- */
/* -----------------------------------------------------
  With secondary section
----------------------------------------------------- */
/* -----------------------------------------------------
  Checkbox tags
----------------------------------------------------- */
/* -----------------------------------------------------
  States
----------------------------------------------------- */
search-bar {
  --font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
search-bar input[type=search] {
  padding-left: calc(var(--gap) * 3);
  padding-right: calc(var(--gap) * 2);
}
search-bar::after {
  --size: 12px;
  content: "";
  position: absolute;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);
  opacity: 0;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 2px solid var(--loader-colour, #969695);
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation-name: loader-rotate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
  animation-play-state: paused;
  left: auto;
  right: var(--gap);
}
@keyframes loader-rotate {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
search-bar.loading::after {
  opacity: 1;
  animation-play-state: running;
}
search-bar:not(.showing-suggestions) ul.results {
  display: none;
}
search-bar.showing-suggestions input[type=search] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
search-bar svg {
  color: #6A6A6A;
  width: var(--font-size);
  height: var(--font-size);
  position: absolute;
  left: var(--gap);
}
search-bar ul.results {
  width: 100%;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px dashed #dcdcdc;
  max-height: 305px;
}
search-bar ul.results li {
  background-color: white;
  transition: background-color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
  cursor: pointer;
  position: relative;
}
search-bar ul.results li + li {
  border-top: 1px solid #F4F4F4;
}
search-bar ul.results li mark {
  background-color: initial;
  color: inherit;
  border-radius: initial;
  padding: initial;
  transition: initial;
  color: #0095FF;
}
search-bar ul.results li a {
  text-decoration: none;
  padding: calc(var(--gap) * 0.5) var(--gap);
  display: block;
  text-align: left;
  font-size: 0.75em;
  line-height: 1.2;
}
search-bar ul.results li a:focus {
  outline: none;
}
search-bar ul.results li.focus a {
  background-color: #10124d;
}
search-bar ul.results li.focus a .type, search-bar ul.results li.focus a {
  color: white;
}
search-bar ul.results li .type {
  display: block;
  color: #969695;
  font-size: 0.7em;
  order: 1;
  transition: color var(--transition-duration, 0.2s) var(--transition-ease, cubic-bezier(0.42, 0, 0.58, 1)) var(--transition-delay, );
}
search-bar ul.results li.no-result {
  background-color: #DF3035;
  margin-top: -1px;
}
search-bar ul.results li.no-result a {
  color: white;
}
search-bar ul.results li:nth-of-type(even) {
  background-color: #F4F4F4;
}
@media (hover: hover) {
  search-bar ul.results li:not(.no-result):hover {
    background-color: #dcdcdc;
  }
}