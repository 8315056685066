@use '_modules' as *;

search-bar {

  --font-size : 16px; 
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  input[type=search]{
    padding-left: $gap3;
    padding-right: $gap2;
  }

  &::after {
    @include loader;
    left:auto; right:$gap; 
  }
  
  &.loading::after {
    @include loader-animation;
  }

  &:not(.showing-suggestions) {
    ul.results { display:none;}
  }

  &.showing-suggestions input[type="search"] {
    border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
  }

  svg {
    color:$font-colour; 
    width:var(--font-size);
    height:var(--font-size);
    position: absolute;
    left: $gap;
  }

  ul.results {
    width:100%;
    position: absolute;
    top:calc(100% - 1px);
    left:0;
    overflow-x: hidden;
    overflow-y: auto;
    border-top:1px dashed $grey4;
    // border-bottom-left-radius: $border-radius;
    // border-bottom-right-radius: $border-radius;
    max-height: 305px;
    li {
      background-color: white;
      transition: background-color $transition;
      cursor:pointer;
      position: relative;
   
      + li {
        border-top:1px solid $grey3;

      }
      mark {
        @include mark-reset();
        color:$blue5;
      }
      a {
        text-decoration: none;
        padding:$gap0 $gap;
        display: block;
        text-align:left;
        font-size:font-size(12); 
        line-height:1.2;
        &:focus {
          outline:none;
        }
      }
      &.focus a {
        background-color: $blue4;
        .type, & { color:white; }
      }
      .type {
        display:block;
        color:$grey;
        font-size:0.7em;
        order:1;
        transition: color $transition;
      }
      &.no-result {
        background-color:$red;
        margin-top:-1px;
        a {
          color:white;
        }
      }
      &:nth-of-type(even) {
        background-color: $grey3;
      }
      &:not(.no-result) {
        @include hover {
          background-color: $grey4;
        }
      }
    }
  }
}