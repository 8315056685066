@use '../_variables/' as *;
@use '../_helpers/' as *;


/* -------------------
	Base result styles
-------------------- */


@mixin base() {

  --heading-colour    : #{$blue};

  --border-thickness  : 1px;
  --border-radius     : 0px;
  --background-colour : white;

  --padding           : 2rem;

  --padding-left      : var(--padding);
  --padding-right     : var(--padding);
  --padding-top       : var(--padding);
  --padding-bottom    : var(--padding);

  --image-width       : 100px;
  --image-height      : 100px;

  --grid-gap          : #{$gap};


  position: relative;
  padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
  border: var(--border-thickness) solid $transparent;
  border-radius: $border-radius;
  background-color: var(--background-colour);
  // box-shadow: 0px 0px 5px 1px rgba($grey, 0.1);
  overflow: hidden;

  transition: border $transition;

  display: grid;
  grid-gap: var(--grid-gap) var(--grid-gap);
  // place-content: start;

  .result-image {
    grid-area: image;
    display: block;

    picture {
      height: var(--image-height);
      
      img {
        width: var(--image-height);
        height: var(--image-width);
      }
    }
  }

  .result-title {
    grid-area: title;
    color: var(--heading-colour);
    a { color: var(--heading-colour) }
  }

  .result-subtitle { grid-area: subtitle }
  .result-body     { grid-area: body }

  &.blog    { @include blog() }
  &.product { @include product() }
  &.page    { @include page() }
}


/* -------------------
	Variations
-------------------- */


@mixin blog() {
  --padding          : 4rem;
  --border-thickness : 0;
  --border-radius    : 0;
  // --background-colour : transparent;

  grid-template-areas:
    "subtitle"
    "title"
    "body";

  .result-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    img {
      width: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(120deg, rgba(white, 1) 45%, transparent 120%);
    }
  }

  .result-title, .result-subtitle { text-transform: uppercase }

  .result-title {     
    @include heading-h2;
    line-height: 1.3;
  }

  .result-subtitle {
    --heading-colour: #{$red};

    display: flex;
    align-items: center;

    h4, time {
      font-size: $font-size;
      height: 100%;
    }

    time {
      color: $grey;
    }
  }

  .result-body { --font-colour: #{$black}; }

  &.featured {
    --padding-right: 5rem;

    grid-column: 1 / span 2;

    .result-title {
      font-weight: 800;
      font-size: $font-size-h2;
    }

    &::before {
      content: 'FEATURED';
      @include featured-badge();
      left: 100%;
      z-index: 1;
    }
  }

  .result-title, .result-subtitle, .result-body {
    z-index: 2;
  }
}



@mixin page() {
  grid-template-areas:
  "image title"
  "image body"
  "image body"
  "image body";

  .result-image {
    img {
      object-fit: cover;
    }
  }
}



@mixin product() {
  --badge-spacing : 3rem;

  grid-template-columns: var(--image-width) auto;

  grid-template-areas:
    "image title"
    "image title"
    "image data"
    "image data";

  // .result-title {
  //   margin-top: $gap;
  // }

  .result-title, .result-body {
    padding-right: calc(var(--badge-spacing) * 2);
  }

  .product-data {
    grid-area: data;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    grid-gap: calc($gap / 2) $gap;
  }

  .result-image {
    img {
      object-fit: contain;
    }
  }


  @include break-in(450) {
    grid-template-areas:
    "image "
    "title"
    "data";

    grid-template-columns: unset;

    .product-data {
      // margin-top: $gap-;
      flex-direction: row;
    }

    // grid-template-columns: auto 1fr;

    .result-title, .result-body { padding-right: 0 }
    // .result-title { align-self: center }
  }


  &.in-stock::before {
    --padding-y : 0.2em;
    --padding-x : 10em;

    content: 'in stock';
    position: absolute;
    top: calc(1em + var(--padding-y));
    right: calc(-1 * var(--padding-x));
    padding: 0.5em var(--padding-x);
    transform: rotate(45deg);
    text-transform: uppercase;
    background-color: $blue;
    color: $white;
  }
}