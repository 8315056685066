////////////////////////////////////////////////////////////////////////////////
// Colours
////////////////////////////////////////////////////////////////////////////////

/// Explicit Colours ===========================================================
/// Explicit colours are a what they are. Green is green, blue is blue... no obscurity.
/// We suffix numbers to define variations of colours in no particular order of shade. 
/// Example: green1, green2, green3, green4. This is so we can immidiately see how 
/// many options we have for a single colour at a glance. If you see $green4, you know this
/// project is using four variations of green. This is also often useful because many development 
/// tools will auto-suggest colours with actual examples of the colour. 
/// The Palletes module (at the bottom of this file) will output shaded named variations, 
//// <colour>-dark and <colour>-light. If you need additional shades please refraim from going down 
/// the 'lighter', 'lightest', 'darker', 'darkest' convention. These are not particuarly legiable 
/// and they are limited by how creative (confusing) your wording can be. 
/// It is better to define a new explcit colour entirely. 
/// @see https://css-tricks.com/what-do-you-name-color-variables/

$blue             : #131f6b; // Primary
$blue2            : #292B68; // Naked Link
$blue3            : #3d3f7f; // Lignt Gradient
$blue4            : #10124d; // Dark Gradient
$blue5            : #0095FF; // Navigation link hover 

$red              : #DF3035; // Secondary - This passes the contrast ratio accessibility test in Googles Lighthouse 

$grey             : #969695;
$grey2            : #6A6A6A; // Font
$grey3            : #F4F4F4; // Lighter Grey Background
$grey4            : #dcdcdc;
$grey5            : #B1B1B1; // Spacers <hr>
$grey6            : #333236; // Navigation link hover  
$grey7            : #e9e9e9;
$green            : #50BC08;
$yellow           : #F6F4DB; // Disclaimer Background
$yellow2          : #FCB002;
$black            : #1E1A1A;
$white            : #FFFFFF;

$orange           : #ed6224;


$transparent      : rgba(255, 255, 255, 0);
$mark             : $blue5; // background colour for <mark> tags

$default-gradient : linear-gradient(to bottom right, $blue 5%, $black 66%);
$reverse-gradient : linear-gradient(120deg, #10124d 0%, $blue 80%);
$light-gradient   : radial-gradient(circle at bottom, #10124d 55%, #172582 100%);


///
$primary: $blue;
///




/// Themes  ====================================================================

$theme-default-page-background    : $default-gradient;
$theme-default-content-background : $transparent;
$theme-default-nav-background     : $transparent;
$theme-default-nav-background-mob : $white;
$theme-default-nav-font           : $white;
$theme-default-nav-font-mob       : $blue;
$theme-default-nav-spacing-colour : rgba($blue, 0.3);
$theme-default-font               : $white;
$theme-default-heading            : $white;
$theme-default-accent					    : $red;
$theme-default-logo               : $white;

$theme-white-page-background      : $light-gradient;
$theme-white-content-background   : #f8f8f8;
$theme-white-nav-background       : $transparent;
$theme-white-nav-background-mob   : $blue;
$theme-white-nav-font             : $white;
$theme-white-nav-font-mob         : $white;
$theme-white-nav-spacing-colour   : rgba($white, 0.3);
$theme-white-font                 : $black;
$theme-white-heading              : $blue;
$theme-white-accent					      : $theme-default-accent;
$theme-white-logo                 : $white;

$theme-black-page-background      : $default-gradient;
$theme-black-content-background   : $black;
$theme-black-nav-background       : $white;
$theme-black-nav-background-mob   : $white;
$theme-black-nav-font             : $blue;
$theme-black-nav-font-mob         : $blue;
$theme-black-nav-spacing-colour   : rgba($blue, 0.3);
$theme-black-font                 : $white;
$theme-black-heading              : $white;
$theme-black-accent					      : $theme-default-accent;
$theme-black-logo                 : $blue;

/// Palette ====================================================================
/// By loading configurations into this 'pallete' module, we will be able
/// to declare variants of a few common colours. You can also adjust the lightness
/// or darkness of each variant by passing in the relevant private rules. 
/// Palette will also expose popular social media colours and the Yello brand colour.
/// @see https://sass-lang.com/documentation/at-rules/forward#configuring-modules
/// @output $green : #02929A; $green-dark : #256569; $green-light: #04b9c4;

@forward 'create/palette'with ($blue : $blue,
	$red : $red,
	$grey : $grey,
	$yellow : $yellow,
	$green : $green,
	$black : $black);

$component-themes : ("red": $red, "blue": $blue, "grey": $grey3, "white" : $white);